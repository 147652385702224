<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('branches_of_business') }} <span class="text-md"> - {{ $t('companies') }}</span> </h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'dashReports'}">{{ $t('dashboard') }}</router-link></li>
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'branches'}">{{ $t('branches_of_business') }}</router-link></li>
                     <li v-if="isEditing" class="breadcrumb-item">{{ $t('edit_branch') }}</li>
                     <li v-else class="breadcrumb-item">{{ $t('create_branch') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_branch') : $t('edit_branch') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-3">
                                    <div class="form-group mb-6">
                                       <label for="title">{{ $t('title') }}*</label>
                                       <input type="text" v-model="branch.name" class="form-control" placeholder="Título">
                                    </div>
                                 </div>

                                 <div class="col-2">
                                    <label for="color">{{ $t('title_size') }}*</label>
                                    <select v-model="branch.titleSize" class="form-control">
                                       <option disabled selected>{{ $t('choose_the_title_size') }}*</option>
                                       <option value="10">10</option>
                                       <option value="10.5">10.5</option>
                                       <option value="11">11</option>
                                       <option value="11.5">11.5</option>
                                       <option value="12">12</option>
                                       <option value="12.5">12.5</option>
                                       <option value="13">13</option>
                                       <option value="13.5">13.5</option>
                                       <option value="14">14</option>
                                       <option value="14.5">14.5</option>
                                       <option value="15">15</option>
                                       <option value="16">16</option>
                                       <option value="17">17</option>
                                       <option value="18">18</option>
                                       <option value="19">19</option>
                                       <option value="20">20</option>
                                    </select>
                                 </div>

                                 <div class="col-3">
                                    <label for="color">{{ $t('title_color') }}*</label>
                                    <select v-model="branch.titleColor" class="form-control">
                                       <option disabled selected>{{ $t('choose_the_title_color') }}*</option>
                                       <option value="#3B81DC">Azul</option>
                                       <option value="#FFFFFF">Branco</option>
                                       <option value="#000000">Preto</option>
                                    </select>
                                 </div>

                                 <div class="row flex-row">
                                    <div class="col-5">
                                       <div class="form-group mt-4">
                                          <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                             <input type="checkbox" v-model="branch.haveLogo" class="custom-control-input" id="customSwitch3">
                                             <label class="custom-control-label" for="customSwitch3" style="white-space: nowrap;">
                                                {{ $t('have_logo') }}
                                             </label>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-7">
                                       <div v-if="branch.haveLogo">
                                          <label for="color" class="text-sm">{{ $t('size_logo') }}*</label>
                                          <select v-model="branch.logoSize" class="form-control">
                                             <option disabled selected>{{ $t('choose_the_size_logo') }}</option>
                                             <option value="small">Pequeno</option>
                                             <option value="medium">Médio</option>
                                             <option value="large">Grande</option>
                                          </select>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-8 my-2">
                                    <label>{{ $t('background_imagem') }}*</label>
                                    <div>
                                       <img
                                          v-if="branch.imageUrl"
                                          :src="branch.imageUrl"
                                          :alt="branch.name"
                                          height="150"
                                          width="300"
                                       />
                                       <a v-else style="border-radius: 50%" class="btn btn-app">
                                          <i class="fas fa-image"></i> {{ $t('image') }}
                                       </a>
                                       <div class="row input-file-container">
                                          <input type="file" id="meuInputFile" class="input-file" @change="onImageChoose" style="display: none;" />
                                          <label for="meuInputFile" class="input-file-trigger btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3">
                                             {{ alreadyIncludeImage || isEditing ? $t('change') : $t('insert') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import {useToast} from "vue-toastification"
import { v4 as uuidV4 } from "uuid"
import VueMultiselect from 'vue-multiselect'
import SaveButton from "../../../components/SaveButton.vue";
import branchService from "@/modules/dashboard/modules/branch/services/branch-service";
import {storageRef} from "@/plugins/firebase";

export default {
   name: "BranchesForm",
   components: { VueMultiselect, SaveButton },
   setup() {
      const toast = useToast()
      return { toast }
   },
   computed: {
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   data () {
      return {
         branch: {
            uuid: uuidV4(),
            name: null,
            titleColor: '#000000',
            titleSize: 15,
            imageUrl: "",
            haveLogo: false,
            logoSize: "small",
         },
         branchImage: null,
         alreadyIncludeImage: false,
         isEditing: false,
         isSaving: false,
         isLoading: false,
         errors: []
      }
   },
   async created() {
      this.isLoading = true;

      try {
         if (this.$route.params.uuid){
            this.isEditing = true;

            const { data: response } = await branchService.get(this.$route.params.uuid);
            this.branch = response;
         }
      } finally {
         this.isLoading = false;
      }
   },
   methods: {
      onImageChoose (event) {
         const file = event.target.files[0]

         const reader = new FileReader()
         reader.onload = () => {
            // the field will be sent on backend as base64 string and apply validations
            this.branchImage = reader.result;
            this.branch.imageUrl = reader.result;
         }
         reader.readAsDataURL(file);
         this.alreadyIncludeImage = true;
      },
      async save () {
         this.isSaving = true;
         try {

            //upload image
            if (this.branchImage !== null) {
               this.toast.info(this.$t('starting_upload_images'));
               const imgExtension = this.branchImage.substring(this.branchImage.indexOf("/") + 1, this.branchImage.indexOf(";base64"));
               const imgName = `branches/${this.branch.uuid}/${this.branch.uuid}.${imgExtension}`;

               await storageRef.child(imgName).putString(this.branchImage, 'data_url');
               this.branch.imageUrl = await storageRef.child(imgName).getDownloadURL();
               this.toast.info(this.$t('the_images_have_been_uploaded_and_we_are_now_saving_the_information'));
            }

            if (this.isEditing) {
               await branchService.save('edit', this.branch);
               this.$router.push({ name: 'branches' });
               this.toast.success(this.$t("updated"));
            }
            else {
               await branchService.save('create', this.branch);
               this.$router.push({ name: 'branches' });
               this.toast.success(this.$t("saved"));
            }
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         } finally {
            this.isSaving = false;
         }
      }
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

